<template>
  <b-modal :id="modalid" :hide-header="true" :hide-footer="true" v-model="show">
    <h5>
      <span class="warning">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="exclamation-circle"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="svg-inline--fa fa-exclamation-circle fa-w-16 fa-5x"
        >
          <path
            fill="currentColor"
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
            class=""
          ></path>
        </svg>
      </span>
      {{ $t('footer.warning') }}
    </h5>
    <p class="my-4">
      {{ message }}
    </p>
    <b-button @click="confirm()" variant="primary">{{
      $t('footer.confirm')
    }}</b-button>
    <b-button @click="hide()" variant="link">{{
      $t('footer.cancel')
    }}</b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    modalid: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    show: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    hide: function() {
      this.$emit('hide')
    },
    confirm: function() {
      this.$emit('confirm')
    },
    cancel: function() {
      this.$emit('cancel')
    }
    //this.$refs[this.modalid].show()
  }
}
</script>

<style scss>
.warning {
  color: #d3732f;
}
</style>
