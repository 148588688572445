import i18n from '../locales'

import axios from 'axios'
import logout from '@/functions/logout'

let axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    //Authorization: `Bearer ${store.state.token}`
  },
  validateStatus: function(status) {
    return status // Resolve only if the status code is less than 500
  }
})

axiosInstance.interceptors.request.use(request => {
  request.headers['accept-language'] = i18n.locale
  return request
})

axiosInstance.interceptors.response.use(response => {
  if (
    !response.config.url.includes('registerUser') &&
    response.status === 401
  ) {
    logout()
  }

  return response
})

export default axiosInstance
