const { axiosCreate } = require('./Api')
import store from '../state/store'
import { getCart } from './CartService'
import i18n from '@/locales/index'

async function getReservations() {
  const result = await axiosCreate.get('shop/reservationOrders', {
    params: {
      CustomerID: store.getters.customerID,
      Orderby: 'DateTimeExpectedStart',
      SortReverse: true,
      Size: 500
    },
    headers: { authorization: store.getters.token }
  })

  return result.data.Collection
}

async function cancelReservation({ reservationID }) {
  const result = await axiosCreate.post(
    `shop/reservationorders/${reservationID}/cancel`,
    {
      Reason: 'Geannuleerd vanuit gebruikersdashboard'
    },
    {
      params: { ContactID: store.getters.contactID },
      headers: { authorization: store.getters.token }
    }
  )

  return result
}

async function getReservationItem({ reservationID }) {
  const result = await axiosCreate.get(
    `shop/reservationOrders/${reservationID}`,
    {
      params: { ContactID: store.getters.contactID },
      headers: { authorization: store.getters.token }
    }
  )

  return result.data
}

async function getReservationReport({
  reservationOrderID,
  reportID,
  attachmentToken,
  fileName
}) {
  window.open(
    `${process.env.VUE_APP_URL}/api/v2/shop/reservationOrders/${reservationOrderID}/documents/${reportID}/${attachmentToken}/${fileName}`
  )

  return null
}

async function addReservationToCart({ reservationID }) {
  const result = await axiosCreate.post(
    `shop/custReservationOrders/${reservationID}/addToCart/${store.getters.contactID}`,
    {},
    {
      headers: {
        authorization: store.getters.token
      }
    }
  )
  await getCart()
  return result
}

async function getReservationItems({ reservationID }) {
  const result = await axiosCreate.get(
    `shop/reservationorders/${reservationID}/items`,
    {
      headers: { authorization: store.getters.token },
      params: { sorton: 'ranking' }
    }
  )

  return result.data.Collection
}

async function getPaymentStatus({ reservationID }) {
  const result = await axiosCreate.get(`shop/payments/link`, {
    params: {
      reservationOrderID: reservationID,
      locale: i18n.locale,
      returnUrl: window.location.href
    },
    headers: { authorization: store.getters.token }
  })

  return result
}

/**
 * Updates the payment status of a reservation
 * @param {string} secretCode Secret code related to Ogone payment
 * @param {string} orderID Order ID
 * @param {string} payID Pay ID
 * @returns {object} result
 */
async function updatePaymentStatus({ secretCode, orderID, payID, status }) {
  const result = await axiosCreate.patch(
    `shop/custPublicOrderController`,
    {
      secretCode,
      orderID,
      payID,
      status
    },
    {
      headers: { authorization: store.getters.token }
    }
  )

  return result
}

async function cancelReservationItem({
  reservationOrderID,
  reservationItemID
}) {
  const result = await axiosCreate.post(
    `shop/custReservationOrders/${reservationOrderID}/items/${reservationItemID}/cancel`,
    {},
    {
      params: { sorton: 'ReservationOrderID' },
      headers: { authorization: store.getters.token }
    }
  )

  return result
}

module.exports = {
  getReservations,
  getReservationItems,
  getReservationItem,
  getReservationReport,
  addReservationToCart,
  cancelReservation,
  cancelReservationItem,
  getPaymentStatus,
  updatePaymentStatus
}
